import {
  LOGO_MINUS_HITBOX,
  LOGO_PANE,
  TERMINAL_MINUS_HITBOX,
  TERMINAL_PANE,
} from './element'

let updateHitbox = null
let locateHitbox = null

function makeDraggable(el) {
  let isDragging = false
  let offsetX, offsetY

  el.addEventListener('mousedown', (e) => {
    isDragging = true
    offsetX = e.clientX - el.getBoundingClientRect().left
    offsetY = e.clientY - el.getBoundingClientRect().top
    el.style.zIndex = '1000'
    el.style.cursor = 'grabbing'
  })

  document.addEventListener('mousemove', (e) => {
    if (!isDragging) return

    const newX = e.clientX - offsetX
    const newY = e.clientY - offsetY

    el.style.left = newX + 'px'
    el.style.top = newY + 'px'

    updateHitbox(el, newX, newY)
  })

  document.addEventListener('mouseup', () => {
    if (isDragging) {
      isDragging = false
      el.style.zIndex = 'auto'
      el.style.cursor = 'grab'
    }
  })
}

export function init() {
  let logoHitboxWidth = LOGO_MINUS_HITBOX.getBoundingClientRect().width
  let terminalHitboxWidth = TERMINAL_MINUS_HITBOX.getBoundingClientRect().width

  let logoWindowWidth = null

  updateHitbox = (el, x, y) => {
    if (!logoWindowWidth && el) {
      logoWindowWidth = el.getBoundingClientRect().width
    }
    if (!locateHitbox) return
    locateHitbox(LOGO_MINUS_HITBOX, x, y, logoWindowWidth, logoHitboxWidth)
  }

  locateHitbox = (hitbox, x, y, logoWindowWidth, logoHitboxWidth) => {
    hitbox.style.left = x + (logoWindowWidth - logoHitboxWidth) + 'px'
    hitbox.style.top = y + 'px'
  }

  const { x: logoX, y: logoY } = LOGO_PANE.getBoundingClientRect()
  const { x: terminalX, y: terminalY } = TERMINAL_PANE.getBoundingClientRect()

  updateHitbox(TERMINAL_PANE, logoX, logoY)

  locateHitbox(
    TERMINAL_MINUS_HITBOX,
    terminalX,
    terminalY,
    TERMINAL_PANE.getBoundingClientRect().width,
    terminalHitboxWidth
  )

  makeDraggable(LOGO_PANE)
}
