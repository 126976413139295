export const ANIMATION_TIME_MS = 500
export const DELAY_TIME_MS = 200

export function toggleDisplay(div, direction) {
  div.style.display = direction === 'hide' ? 'none' : 'block'
}

export function toggleAnim(div, type, direction) {
  div.classList.remove(`${type}-${direction === 'out' ? 'in' : 'out'}`)
  div.classList.add(`${type}-${direction === 'out' ? 'out' : 'in'}`)
}

export function locateButton(button) {
  const buttons = document.querySelectorAll('.buttonTracker')
  if (buttons?.length !== 0) {
    let highestRight = 0
    buttons.forEach((button) => {
      const { right } = button.getBoundingClientRect()
      if (right > highestRight) {
        highestRight = right
      }
    })
    button.style.left = highestRight + 6 + 'px'
  } else {
    button.style.left = 45 + 'px'
  }
  button.classList.add('buttonTracker')
}

export function shiftButtons(div, closedButtonLeft, closedButtonWidth) {
  div.classList.remove('buttonTracker')
  const buttons = document.querySelectorAll('.buttonTracker')
  if (buttons?.length !== 0) {
    buttons.forEach((button) => {
      const { left: currentButtonLeft } = button.getBoundingClientRect()
      if (currentButtonLeft > closedButtonLeft) {
        button.style.left = currentButtonLeft - closedButtonWidth - 4 + 'px'
      }
    })
  }
}
