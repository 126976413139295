import { removeUndefined } from './helper'
import * as element from './element'
import addActions from './action'

const HITBOX_TYPES = [
  {
    id: 'MINUS',
    elements: ['LOGO', 'TERMINAL', 'WORK', 'EXPERIMENTS'],
    suffix: '_MINUS_HITBOX',
  },
  {
    id: 'PLUS',
    elements: ['WORK', 'EXPERIMENTS'],
    suffix: '_PLUS_HITBOX',
  },
  {
    id: 'BUTTON',
    elements: ['LOGO', 'TERMINAL', 'WORK', 'EXPERIMENTS'],
    suffix: '_BUTTON',
  },
  {
    id: 'MENU',
    elements: ['MENU'],
    suffix: '_HITBOX',
    extra: true,
  },
  {
    id: 'MENU_ITEM',
    elements: ['WORK', 'EXPERIMENTS', 'INFO'],
    suffix: '_HITBOX',
    extra: true,
  },
]

function getHitboxes(ids, suffix, extra) {
  return ids.map((id) => {
    return removeUndefined({
      el: element[`${id}${suffix}`],
      pane: element[`${id}_PANE`],
      minus: element[`${id}_MINUS_HITBOX`],
      plus: element[`${id}_PLUS_HITBOX`],
      button: element[`${id}_BUTTON`],
      menu: extra ? element.MENU_PANE : undefined,
      items: extra
        ? [element.WORK_HITBOX, element.EXPERIMENTS_HITBOX, element.INFO_HITBOX]
        : undefined,
    })
  })
}

export function init() {
  HITBOX_TYPES.forEach((type) => {
    getHitboxes(type.elements, type.suffix, type.extra).forEach(
      addActions(type.id)
    )
  })
}
