import {
  ANIMATION_TIME_MS,
  DELAY_TIME_MS,
  toggleDisplay,
  toggleAnim,
  locateButton,
  shiftButtons,
} from './anim'
import { PLUS_HEIGHT } from './element'

function minusActions({ el, plus, button, pane }) {
  el.addEventListener('click', () => {
    toggleDisplay(button, 'show')
    toggleAnim(pane, 'pane', 'out')

    toggleDisplay(el, 'hide')
    if (plus) toggleDisplay(plus, 'hide')

    setTimeout(() => {
      toggleDisplay(pane, 'hide')
      locateButton(button)
      toggleAnim(button, 'button', 'in')
    }, ANIMATION_TIME_MS + DELAY_TIME_MS)
  })
}

function plusActions({ el, minus, pane }) {
  el.addEventListener('click', () => {
    toggleAnim(pane, 'pane', 'out')

    toggleDisplay(minus, 'hide')
    toggleDisplay(el, 'hide')
  })
}

function buttonActions({ el, pane, minus, plus }) {
  el.addEventListener('click', () => {
    toggleDisplay(pane, 'show')
    const { left, width } = el.getBoundingClientRect()
    toggleAnim(el, 'button', 'out')

    if (minus) toggleDisplay(minus, 'show')
    if (plus) toggleDisplay(plus, 'show')

    setTimeout(() => {
      shiftButtons(el, left, width)
      toggleDisplay(el, 'hide')
      toggleAnim(pane, 'pane', 'in')
    }, ANIMATION_TIME_MS + DELAY_TIME_MS)
  })
}

let out = false

function menuItemActions({ el, pane, minus, plus, menu, items }) {
  el.addEventListener('click', () => {
    toggleAnim(pane, 'pane', 'in')

    toggleDisplay(minus, 'show')
    toggleDisplay(plus, 'show')

    toggleAnim(menu, 'menu', 'out')

    items.forEach((item) => {
      toggleDisplay(item, 'hide')
    })

    toggleAnim(PLUS_HEIGHT, 'plus', out ? 'in' : 'out')

    out = !out
  })
}

function menuActions({ el, pane, items }) {
  el.addEventListener('click', () => {
    toggleAnim(pane, 'menu', out ? 'in' : 'out')
    toggleAnim(pane, 'menu', out ? 'out' : 'in')

    toggleAnim(PLUS_HEIGHT, 'plus', out ? 'in' : 'out')

    items.forEach((item) => {
      toggleDisplay(item, out ? 'hide' : 'show')
    })

    out = !out
  })
}

export default function addActions(type) {
  switch (type) {
    case 'MINUS':
      return minusActions
    case 'PLUS':
      return plusActions
    case 'BUTTON':
      return buttonActions
    case 'MENU':
      return menuActions
    case 'MENU_ITEM':
      return menuItemActions
  }
}
