import './style.css'
import * as hitbox from './hitbox'
import * as draggable from './draggable'

import * as THREE from 'three'
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { OutputPass } from 'three/examples/jsm/postprocessing/OutputPass'
import { OutlinePass } from 'three/addons/postprocessing/OutlinePass.js'

import { OrbitControls } from 'three/addons/controls/OrbitControls.js'

const workMode = false
const debugControls = false

var canvas = document.getElementById('canvas')

hitbox.init()
draggable.init()

const mobile =
  navigator.userAgent.match(/Android/i) ||
  navigator.userAgent.match(/webOS/i) ||
  navigator.userAgent.match(/iPhone/i) ||
  navigator.userAgent.match(/BlackBerry/i) ||
  navigator.userAgent.match(/Windows Phone/i)

//Variables for blade mesh
var joints = 3
var bladeWidth = 0.09
var bladeHeight = 0.5

//Patch side length
const width = 100
//Number of vertices on ground plane side
var resolution = 64
//Distance between two ground plane vertices
var delta = width / resolution
//Radius of the sphere onto which the ground plane is bent
var radius = 240

//The global coordinates
//The geometry never leaves a box of width*width around (0, 0)
//But we track where in space the camera would be globally
var pos = new THREE.Vector2(0.01, 0.01)

//Number of blades
var instances = 80000
if (mobile) {
  instances = 7000
  width = 50
}

//Sun
//Height over horizon in range [0, PI/2.0]
var elevation = 0.5
//Rotation around Y axis in range [0, 2*PI]
var azimuth = 0.01

// TODO: Remember to remove this or reenable it
var fogFade = 1

//Lighting variables for grass
var ambientStrength = 0.7
var translucencyStrength = 1.5
var specularStrength = 0.5
var diffuseStrength = 1.5
var shininess = 256
var sunColour = new THREE.Vector3(1.0, 1.0, 1.0)
var specularColour = new THREE.Vector3(1.0, 1.0, 1.0)
const scene = new THREE.Scene()

var renderer = new THREE.WebGLRenderer({ antialias: true, canvas: canvas })
renderer.setPixelRatio(window.devicePixelRatio)
renderer.setSize(window.innerWidth, window.innerHeight)

var FOV = 25 //2 * Math.atan(window.innerHeight / distance) * 180 / Math.PI;
var camera = new THREE.PerspectiveCamera(
  FOV,
  window.innerWidth / window.innerHeight,
  1,
  1000
)

scene.add(camera)

// Light for ground plane
const ambientLight = new THREE.AmbientLight(0xffffff, 0.5)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 5)
directionalLight.position.set(5, 20, -20)
scene.add(directionalLight)

const frontDirectionalLight = new THREE.DirectionalLight(0xffffff, 0.25)
frontDirectionalLight.position.set(5, 5, 20)
scene.add(frontDirectionalLight)

//Get alpha map and blade texture
//These have been taken from "https://cdn.skypack.dev/Realistic real-time grass rendering" by Eddie Lee, 2010
var loader = new THREE.TextureLoader()
loader.crossOrigin = ''
var grassTexture = loader.load(
  'https://al-ro.github.io/images/grass/blade_diffuse.jpg'
)
var alphaMap = loader.load(
  'https://al-ro.github.io/images/grass/blade_alpha.jpg'
)
var noiseTexture = loader.load(
  'https://al-ro.github.io/images/grass/perlinFbm.jpg'
)
noiseTexture.wrapS = THREE.RepeatWrapping
noiseTexture.wrapT = THREE.RepeatWrapping

import skyVertex from '../public/shaders/sky/index.vert'
import skyFragment from '../public/shaders/sky/index.frag'

//************** Sky **************
const skyGeometry = new THREE.SphereGeometry(256, 32, 32)
const skyMaterial = new THREE.ShaderMaterial({
  uniforms: {
    uTopColor: { value: new THREE.Color('#629BDC') },
    uBottomColor: { value: new THREE.Color('#629BDC') },
    uSpotColor: { value: new THREE.Color('white') },
    uSpotPosition: { value: [0.7, 0.4] },
  },
  vertexShader: skyVertex,
  fragmentShader: skyFragment,
  side: THREE.BackSide,
})
const skyMesh = new THREE.Mesh(skyGeometry, skyMaterial)
if (!workMode) scene.add(skyMesh)

//************** Ground **************
//Ground material is a modification of the existing THREE.MeshPhongMaterial rather than one from scratch
var groundBaseGeometry = new THREE.PlaneGeometry(
  width,
  width,
  resolution,
  resolution
)
groundBaseGeometry.lookAt(new THREE.Vector3(0, 1, 0))
groundBaseGeometry.verticesNeedUpdate = true

var groundGeometry = new THREE.PlaneGeometry(
  width,
  width,
  resolution,
  resolution
)
groundGeometry.setAttribute(
  'basePosition',
  groundBaseGeometry.getAttribute('position')
)
groundGeometry.lookAt(new THREE.Vector3(0, 1, 0))
groundGeometry.verticesNeedUpdate = true
var groundMaterial = new THREE.MeshStandardMaterial({
  color: new THREE.Color('rgb(0%, 15%, 0%)'),
  roughness: 1
})

import groundNormal from '../public/shaders/ground/normal.vert'
import groundBegin from '../public/shaders/ground/begin.vert'
import groundVertex from '../public/shaders/ground/index.vert'

var groundShader
groundMaterial.onBeforeCompile = function (shader) {
  shader.uniforms.delta = { value: delta }
  shader.uniforms.posX = { value: pos.x }
  shader.uniforms.posZ = { value: pos.y }
  shader.uniforms.radius = { value: radius }
  shader.uniforms.width = { value: width }
  shader.uniforms.noiseTexture = { value: noiseTexture }
  shader.vertexShader = groundVertex + shader.vertexShader
  shader.vertexShader = shader.vertexShader.replace(
    '#include <beginnormal_vertex>',
    groundNormal
  )
  shader.vertexShader = shader.vertexShader.replace(
    '#include <begin_vertex>',
    groundBegin
  )
  groundShader = shader
}

var ground = new THREE.Mesh(groundGeometry, groundMaterial)

ground.geometry.computeVertexNormals()
if (!workMode) scene.add(ground)

//************** Grass **************

//Define base geometry that will be instanced. We use a plane for an individual blade of grass
var grassBaseGeometry = new THREE.PlaneGeometry(
  bladeWidth,
  bladeHeight,
  1,
  joints
)
grassBaseGeometry.translate(0, bladeHeight / 2, 0)

//Define the bend of the grass blade as the combination of three quaternion rotations
let vertex = new THREE.Vector3()
let quaternion0 = new THREE.Quaternion()
let quaternion1 = new THREE.Quaternion()
let x, y, z, w, angle, sinAngle, rotationAxis

//Rotate around Y
angle = 0.05
sinAngle = Math.sin(angle / 2.0)
rotationAxis = new THREE.Vector3(0, 1, 0)
x = rotationAxis.x * sinAngle
y = rotationAxis.y * sinAngle
z = rotationAxis.z * sinAngle
w = Math.cos(angle / 2.0)
quaternion0.set(x, y, z, w)

//Rotate around X
angle = 0.3
sinAngle = Math.sin(angle / 2.0)
rotationAxis.set(1, 0, 0)
x = rotationAxis.x * sinAngle
y = rotationAxis.y * sinAngle
z = rotationAxis.z * sinAngle
w = Math.cos(angle / 2.0)
quaternion1.set(x, y, z, w)

//Combine rotations to a single quaternion
quaternion0.multiply(quaternion1)

//Rotate around Z
angle = 0.1
sinAngle = Math.sin(angle / 2.0)
rotationAxis.set(0, 0, 1)
x = rotationAxis.x * sinAngle
y = rotationAxis.y * sinAngle
z = rotationAxis.z * sinAngle
w = Math.cos(angle / 2.0)
quaternion1.set(x, y, z, w)

//Combine rotations to a single quaternion
quaternion0.multiply(quaternion1)

let quaternion2 = new THREE.Quaternion()

//Bend grass base geometry for more organic look
for (
  let v = 0;
  v < grassBaseGeometry.attributes.position.array.length;
  v += 3
) {
  quaternion2.setFromAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI / 2)
  vertex.x = grassBaseGeometry.attributes.position.array[v]
  vertex.y = grassBaseGeometry.attributes.position.array[v + 1]
  vertex.z = grassBaseGeometry.attributes.position.array[v + 2]
  let frac = vertex.y / bladeHeight
  quaternion2.slerp(quaternion0, frac)
  vertex.applyQuaternion(quaternion2)
  grassBaseGeometry.attributes.position.array[v] = vertex.x
  grassBaseGeometry.attributes.position.array[v + 1] = vertex.y
  grassBaseGeometry.attributes.position.array[v + 2] = vertex.z
}

grassBaseGeometry.computeVertexNormals()
var baseMaterial = new THREE.MeshNormalMaterial({ side: THREE.DoubleSide })
var baseBlade = new THREE.Mesh(grassBaseGeometry, baseMaterial)
//Show grass base geometry
if (!workMode) scene.add(baseBlade)

var instancedGeometry = new THREE.InstancedBufferGeometry()

instancedGeometry.index = grassBaseGeometry.index
instancedGeometry.attributes.position = grassBaseGeometry.attributes.position
instancedGeometry.attributes.uv = grassBaseGeometry.attributes.uv
instancedGeometry.attributes.normal = grassBaseGeometry.attributes.normal

// Each instance has its own data for position, orientation and scale
var indices = []
var offsets = []
var scales = []
var halfRootAngles = []

//For each instance of the grass blade
for (let i = 0; i < instances; i++) {
  indices.push(i / instances)

  //Offset of the roots
  x = Math.random() * width - width / 2
  z = Math.random() * width - width / 2
  y = 0
  offsets.push(x, y, z)

  //Random orientation
  let angle = Math.PI - Math.random() * (2 * Math.PI)
  halfRootAngles.push(Math.sin(0.5 * angle), Math.cos(0.5 * angle))

  //Define variety in height
  if (i % 3 != 0) {
    scales.push(2.0 + Math.random() * 1.25)
  } else {
    scales.push(2.0 + Math.random())
  }
}

var offsetAttribute = new THREE.InstancedBufferAttribute(
  new Float32Array(offsets),
  3
)
var scaleAttribute = new THREE.InstancedBufferAttribute(
  new Float32Array(scales),
  1
)
var halfRootAngleAttribute = new THREE.InstancedBufferAttribute(
  new Float32Array(halfRootAngles),
  2
)
var indexAttribute = new THREE.InstancedBufferAttribute(
  new Float32Array(indices),
  1
)

instancedGeometry.setAttribute('offset', offsetAttribute)
instancedGeometry.setAttribute('scale', scaleAttribute)
instancedGeometry.setAttribute('halfRootAngle', halfRootAngleAttribute)
instancedGeometry.setAttribute('index', indexAttribute)

import grassVertex from '../public/shaders/grass/index.vert'
import grassFragment from '../public/shaders/grass/index.frag'

//Define the material, specifying attributes, uniforms, shaders etc.
var grassMaterial = new THREE.RawShaderMaterial({
  uniforms: {
    time: { type: 'float', value: 0 },
    delta: { type: 'float', value: delta },
    posX: { type: 'float', value: pos.x },
    posZ: { type: 'float', value: pos.y },
    radius: { type: 'float', value: radius },
    width: { type: 'float', value: width },
    map: { value: grassTexture },
    alphaMap: { value: alphaMap },
    noiseTexture: { value: noiseTexture },
    sunDirection: {
      type: 'vec3',
      value: new THREE.Vector3(
        Math.sin(azimuth),
        Math.sin(elevation),
        -Math.cos(azimuth)
      ),
    },
    cameraPosition: { type: 'vec3', value: camera.position },
    ambientStrength: { type: 'float', value: ambientStrength },
    translucencyStrength: { type: 'float', value: translucencyStrength },
    diffuseStrength: { type: 'float', value: diffuseStrength },
    specularStrength: { type: 'float', value: specularStrength },
    shininess: { type: 'float', value: shininess },
    lightColour: { type: 'vec3', value: sunColour },
    specularColour: { type: 'vec3', value: specularColour },
  },
  vertexShader: grassVertex,
  fragmentShader: grassFragment,
  side: THREE.DoubleSide,
})

var grass = new THREE.Mesh(instancedGeometry, grassMaterial)
if (!workMode) scene.add(grass)

//******* Sun uniform update *******
function updateSunPosition() {
  var sunDirection = new THREE.Vector3(
    Math.sin(azimuth),
    Math.sin(elevation),
    -Math.cos(azimuth)
  )
  grassMaterial.uniforms.sunDirection.value = sunDirection
  backgroundMaterial.uniforms.sunDirection.value = sunDirection
}

const modelLoader = new GLTFLoader()

// Add rocks
if (!workMode) {
  modelLoader.load('./models/rock_1.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/rock_2.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/rock_3.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/rock_4.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/rock_5.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/rock_6.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/rock_7.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/rock_8.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/rock_9.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/rock_10.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/rock_11.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/rock_12.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/rock_13.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/rock_14.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/rock_15.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
}

if (!workMode) {
  modelLoader.load('./models/secondary_rock_1.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/secondary_rock_2.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/secondary_rock_3.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/secondary_rock_4.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/secondary_rock_5.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/secondary_rock_6.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/secondary_rock_7.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/secondary_rock_8.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/secondary_rock_9.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/secondary_rock_10.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/secondary_rock_11.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/secondary_rock_12.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/secondary_rock_13.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/secondary_rock_14.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/secondary_rock_15.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/secondary_rock_16.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/secondary_rock_17.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
  modelLoader.load('./models/secondary_rock_18.glb', (gltf) => {
    const rocks = gltf.scene
    rocks.position.set(0, 0.9, 5)
    scene.add(rocks)
  })
}

// Add scaffold
const scaffoldMaterial = new THREE.MeshStandardMaterial({
  color: 0xffffff,
  metalness: 1,
  roughness: 0.2,
})

let scaffold
if (!workMode || true) {
  modelLoader.load('./models/scaffold.glb', (gltf) => {
    gltf.scene.traverse((obj) => {
      if (obj.isMesh) {
        obj.position.set(0, -20, 0)
        obj.material = scaffoldMaterial
        scaffold = obj
        scene.add(obj)
      }
    })
  })
}

let scaffoldUp = false

const guideButton = document.querySelector('.guide')
const guideText = document.querySelector('.guideText')
guideButton.addEventListener('click', () => {
  guideText.innerHTML = 'loading<span class="tight">...</span>'
  guideText.classList.add('loading')
  guideButton.classList.remove('bgGreen')
  guideButton.classList.add('bgRed')
  scaffoldUp = !scaffoldUp
})

let orbit

document.addEventListener('mousemove', function (e) {
  let scale = -0.0002
  orbit.rotateY(e.movementX * scale)
  orbit.rotateX(e.movementY * scale)
  orbit.rotation.z = 0 //this is important to keep the camera level..
})

//the camera rotation pivot
orbit = new THREE.Object3D()
orbit.rotation.order = 'YXZ' //this is important to keep level, so Z should be the last axis to rotate in order...
orbit.position.y += 11
orbit.rotateY(THREE.MathUtils.degToRad(-15))
orbit.rotateX(THREE.MathUtils.degToRad(-15))
scene.add(orbit)

//offset the camera and add it to the pivot
//you could adapt the code so that you can 'zoom' by changing the z value in camera.position in a mousewheel event..
let cameraDistance = 80
camera.position.z = cameraDistance
camera.position.y -= 6
orbit.add(camera)

const renderPass = new RenderPass(scene, camera)
const composer = new EffectComposer(renderer)
composer.addPass(renderPass)

const outlinePass = new OutlinePass(
  new THREE.Vector2(window.innerWidth, window.innerHeight),
  scene,
  camera
)
composer.addPass(outlinePass)

const outputPass = new OutputPass()
composer.addPass(outputPass)

const glyphMeshes = []
const glyphHitboxes = []
const glyphHitboxGeometry = new THREE.BoxGeometry(3, 3, 3)
const glyphHitboxMaterial = new THREE.MeshBasicMaterial({
  transparent: true,
  opacity: 0,
})

// Add glyph 1
let glyph_1
if (!workMode) {
  const positions = [-9.5, 6, -7]
  const glyphHitbox = new THREE.Mesh(glyphHitboxGeometry, glyphHitboxMaterial)
  glyphHitbox.position.set(...positions)
  glyphHitboxes.push(glyphHitbox)
  scene.add(glyphHitbox)

  modelLoader.load('./models/glyph_1.glb', (gltf) => {
    gltf.scene.traverse((obj) => {
      if (obj.isMesh) {
        obj.position.set(...positions)
        glyph_1 = obj
        scene.add(glyph_1)
        glyphMeshes.push(glyph_1)
      }
    })
  })
}

// Add glyph 2
let glyph_2
if (!workMode) {
  const positions = [-4, 3.4, 15]
  const glyphHitbox = new THREE.Mesh(glyphHitboxGeometry, glyphHitboxMaterial)
  glyphHitbox.position.set(...positions)
  glyphHitboxes.push(glyphHitbox)
  scene.add(glyphHitbox)

  modelLoader.load('./models/glyph_2.glb', (gltf) => {
    gltf.scene.traverse((obj) => {
      if (obj.isMesh) {
        obj.position.set(-4, 3.4, 15)
        glyph_2 = obj
        scene.add(glyph_2)
        glyphMeshes.push(glyph_2)
      }
    })
  })
}

// Add glyph 3
let glyph_3
if (!workMode) {
  const positions = [5.6, 4.4, -7.6]
  const glyphHitbox = new THREE.Mesh(glyphHitboxGeometry, glyphHitboxMaterial)
  glyphHitbox.position.set(...positions)
  glyphHitboxes.push(glyphHitbox)
  scene.add(glyphHitbox)

  modelLoader.load('./models/glyph_3.glb', (gltf) => {
    gltf.scene.traverse((obj) => {
      if (obj.isMesh) {
        obj.position.set(5.6, 4.4, -7.6)
        glyph_3 = obj
        scene.add(glyph_3)
        glyphMeshes.push(glyph_3)
      }
    })
  })
}

// Add glyph 4
let glyph_4
if (!workMode) {
  const positions = [4.2, 2.25, 20]
  const glyphHitbox = new THREE.Mesh(glyphHitboxGeometry, glyphHitboxMaterial)
  glyphHitbox.position.set(...positions)
  glyphHitboxes.push(glyphHitbox)
  scene.add(glyphHitbox)

  modelLoader.load('./models/glyph_4.glb', (gltf) => {
    gltf.scene.traverse((obj) => {
      if (obj.isMesh) {
        obj.position.set(4.2, 2.25, 20)
        glyph_4 = obj
        scene.add(glyph_4)
        glyphMeshes.push(glyph_4)
      }
    })
  })
}

// Add glyph 5
let glyph_5
if (!workMode) {
  const positions = [8.75, 5.2, 16]
  const glyphHitbox = new THREE.Mesh(glyphHitboxGeometry, glyphHitboxMaterial)
  glyphHitbox.position.set(...positions)
  glyphHitboxes.push(glyphHitbox)
  scene.add(glyphHitbox)

  modelLoader.load('./models/glyph_5.glb', (gltf) => {
    gltf.scene.traverse((obj) => {
      if (obj.isMesh) {
        obj.position.set(8.75, 5.2, 16)
        glyph_5 = obj
        scene.add(glyph_5)
        glyphMeshes.push(glyph_5)
      }
    })
  })
}

// Glyph Hitboxes
let glyphHitboxesState = Array(glyphHitboxes.length).fill(false)
const raycaster = new THREE.Raycaster()
const mouse = new THREE.Vector2()

const isHovered = (el) => el === true

window.addEventListener('mousemove', onMouseMove)

function onMouseMove(event) {
  mouse.x = (event.clientX / window.innerWidth) * 2 - 1
  mouse.y = -(event.clientY / window.innerHeight) * 2 + 1

  raycaster.setFromCamera(mouse, camera)

  const intersects = raycaster.intersectObjects(glyphHitboxes, true)

  if (intersects.length > 0) {
    const hoveredMesh = intersects[0].object

    for (let i = 0; i < glyphHitboxes.length; i++) {
      if (hoveredMesh === glyphHitboxes[i]) {
        glyphHitboxesState[i] = true
      }
    }
  } else {
    glyphHitboxesState = Array(glyphHitboxes.length).fill(false)
  }
}

// Fog
scene.fog = new THREE.Fog(0xd4e4c5, 50, 550)

// Orbs
const orb = loader.load('./textures/orb.png')
const orbGeometry = new THREE.BufferGeometry()
const orbCount = 30
const orbPosArray = new Float32Array(orbCount * 3)

for (let i = 0; i < orbCount * 3; i++) {
  orbPosArray[i] = (Math.random() - 0.5) * 35
}

orbGeometry.setAttribute('position', new THREE.BufferAttribute(orbPosArray, 3))

const orbMaterial = new THREE.PointsMaterial({
  size: 2,
  map: orb,
  transparent: true,
})

const orbMesh = new THREE.Points(orbGeometry, orbMaterial)
if (!workMode) scene.add(orbMesh)

// Clouds
import cloudFragment from '../public/shaders/cloud/index.frag'
import cloudVertex from '../public/shaders/cloud/index.vert'

const cloudTexture = new THREE.TextureLoader().load(
  '/textures/cloudTexture.png'
)
const cloudShape = new THREE.TextureLoader().load('/textures/cloudShape.png')

const cloudUniforms = {
  uTxtCloudNoise: new THREE.Uniform(cloudTexture),
  uTxtShape: new THREE.Uniform(cloudShape),
  uTime: { value: 1 },
  uFac1: { value: 18.0 },
  uTimeFactor1: { value: 0.2 },
  uDisplStrength1: { value: 0.04 },
  uFac2: { value: 5.7 },
  uTimeFactor2: { value: 0.15 },
  uDisplStrength2: { value: 0.08 },
}

const cloudUniforms2 = {
  uTxtCloudNoise: new THREE.Uniform(cloudTexture),
  uTxtShape: new THREE.Uniform(cloudShape),
  uTime: { value: 50 },
  uFac1: { value: 18.0 },
  uTimeFactor1: { value: 0.2 },
  uDisplStrength1: { value: 0.04 },
  uFac2: { value: 5.7 },
  uTimeFactor2: { value: 0.15 },
  uDisplStrength2: { value: 0.08 },
}

const cloudUniforms3 = {
  uTxtCloudNoise: new THREE.Uniform(cloudTexture),
  uTxtShape: new THREE.Uniform(cloudShape),
  uTime: { value: 50 },
  uFac1: { value: 18.0 },
  opacity: { value: 0.4 },
  uTimeFactor1: { value: 0.2 },
  uDisplStrength1: { value: 0.04 },
  uFac2: { value: 5.7 },
  uTimeFactor2: { value: 0.15 },
  uDisplStrength2: { value: 0.08 },
}

const cloudMaterial = new THREE.ShaderMaterial({
  uniforms: {
    ...THREE.UniformsUtils.clone(THREE.ShaderLib.sprite.uniforms),
    ...cloudUniforms,
  },
  vertexShader: cloudVertex,
  fragmentShader: cloudFragment,
  transparent: true,
})

const cloudMaterial2 = new THREE.ShaderMaterial({
  uniforms: {
    ...THREE.UniformsUtils.clone(THREE.ShaderLib.sprite.uniforms),
    ...cloudUniforms2,
  },
  vertexShader: cloudVertex,
  fragmentShader: cloudFragment,
  transparent: true,
})

const cloudMaterial3 = new THREE.ShaderMaterial({
  uniforms: {
    ...THREE.UniformsUtils.clone(THREE.ShaderLib.sprite.uniforms),
    ...cloudUniforms3,
  },
  vertexShader: cloudVertex,
  fragmentShader: cloudFragment,
  transparent: true,
})

// TODO: Arrange these so they are left to right
const cloudSprite = new THREE.Sprite(cloudMaterial)
const cloudSprite2 = new THREE.Sprite(cloudMaterial)
const cloudSprite3 = new THREE.Sprite(cloudMaterial2)
const cloudSprite4 = new THREE.Sprite(cloudMaterial)
const cloudSprite5 = new THREE.Sprite(cloudMaterial2)
const cloudSprite6 = new THREE.Sprite(cloudMaterial2)
const cloudSprite7 = new THREE.Sprite(cloudMaterial)

const cloudSprite8 = new THREE.Sprite(cloudMaterial3)

cloudSprite.scale.set(40, 40, 40)
cloudSprite.position.set(-15, 10, -100)
cloudSprite2.scale.set(80, 80, 80)
cloudSprite2.position.set(0, 2, -80)
cloudSprite3.scale.set(120, 120, 120)
cloudSprite3.position.set(60, -5, -80)
cloudSprite4.scale.set(50, 50, 50)
cloudSprite4.position.set(100, -20, -100)
cloudSprite5.scale.set(50, 50, 50)
cloudSprite5.position.set(-20, -15, -100)
cloudSprite6.scale.set(100, 100, 100)
cloudSprite6.position.set(-40, 5, -100)
cloudSprite7.scale.set(120, 120, 120)
cloudSprite7.position.set(140, 5, -100)

cloudSprite8.scale.set(120, 120, 120)
cloudSprite8.position.set(0, 15, 40)

if (!workMode) {
  scene.add(cloudSprite)
  scene.add(cloudSprite2)
  scene.add(cloudSprite3)
  scene.add(cloudSprite4)
  scene.add(cloudSprite5)
  scene.add(cloudSprite6)
  scene.add(cloudSprite7)
  scene.add(cloudSprite8)
}

outlinePass.edgeStrength = 4
outlinePass.edgeGlow = 2
outlinePass.edgeThickness = 2
const outlineColor = '#A952DE'
outlinePass.visibleEdgeColor.set(outlineColor)
outlinePass.hiddenEdgeColor.set(outlineColor)
outlinePass.selectedObjects = glyphMeshes

//************** Draw **************
let time = 0
let lastFrame = Date.now()
let thisFrame
let dT = 0

// Glyph values
let glyph1StartPos = 0
let glyph1Height = 2
let glyph1Amplitude = 0.25
let glyph1Frequency = 140

let glyph2StartPos = 0
let glyph2Height = 2
let glyph2Amplitude = 0.2
let glyph2Frequency = 130

let glyph3StartPos = 0
let glyph3Height = 2
let glyph3Amplitude = 0.3
let glyph3Frequency = 140

let glyph4StartPos = 0
let glyph4Height = 2
let glyph4Amplitude = 0.2
let glyph4Frequency = 140

let glyph5StartPos = 0
let glyph5Height = 2
let glyph5Amplitude = 0.2
let glyph5Frequency = 130

let sineX = 4

function getSine(startPos, height, amplitude, frequency) {
  let sineY = 0
  let xOffset = 10

  sineY = height / 2 + amplitude * Math.sin((sineX + xOffset) / frequency)
  sineX++

  return sineY + (startPos - height / 2)
}

let firstRun = true
let loc = 0

// Camera shake
import { createNoise2D } from 'simplex-noise'
const noise = createNoise2D()
const originalPosition = camera.position.clone()
const intensity = 1.3

// Orbit Controls
if (debugControls) new OrbitControls(orbit, renderer.domElement)

function draw() {
  //Update time
  thisFrame = Date.now()
  dT = (thisFrame - lastFrame) / 200.0
  time += dT
  lastFrame = thisFrame

  // Grass
  grassMaterial.uniforms.time.value = time

  // Orbs
  orbMesh.rotation.y += 0.0025

  // Cloud
  cloudMaterial.uniforms.uTime.value = time / 10
  cloudMaterial2.uniforms.uTime.value = time / 10

  // Get all glyphs start positions
  if (firstRun) {
    const glyphs = glyph_1 && glyph_2 && glyph_3 && glyph_4 && glyph_5

    if (glyphs) {
      glyph1StartPos = glyph_1.position.y
      glyph2StartPos = glyph_2.position.y
      glyph3StartPos = glyph_3.position.y
      glyph4StartPos = glyph_4.position.y
      glyph5StartPos = glyph_5.position.y

      firstRun = false
    }
  }

  // Animate glyphs
  if (glyph_1) {
    glyph_1.rotation.y -= 0.03
    glyph_1.position.y = getSine(
      glyph1StartPos,
      glyph1Height,
      glyph1Amplitude,
      glyph1Frequency
    )
  }

  if (glyph_2) {
    glyph_2.rotation.y -= 0.02
    glyph_2.position.y = getSine(
      glyph2StartPos,
      glyph2Height,
      glyph2Amplitude,
      glyph2Frequency
    )
  }

  if (glyph_3) {
    glyph_3.rotation.y -= 0.035
    glyph_3.position.y = getSine(
      glyph3StartPos,
      glyph3Height,
      glyph3Amplitude,
      glyph3Frequency
    )
  }

  if (glyph_4) {
    glyph_4.rotation.y -= 0.028
    glyph_4.position.y = getSine(
      glyph4StartPos,
      glyph4Height,
      glyph4Amplitude,
      glyph4Frequency
    )
  }

  if (glyph_5) {
    glyph_5.rotation.y -= 0.032
    glyph_5.position.y = getSine(
      glyph5StartPos,
      glyph5Height,
      glyph5Amplitude,
      glyph5Frequency
    )
  }

  // Glyph spin
  if (glyphHitboxesState.includes(true)) {
    const hoveredIndex = glyphHitboxesState.findIndex(isHovered)
    glyphMeshes[hoveredIndex].rotation.y -= 0.3
    document.body.style.cursor = 'pointer'
  } else {
    document.body.style.cursor = 'auto'
  }

  // Scaffold
  if (scaffold && scaffoldUp && scaffold.position.y < 0) {
    scaffold.position.y += 0.05
  }

  if (scaffold && scaffoldUp && scaffold.position.y > 0) {
    guideText.innerText = 'Guide: on'
    guideText.classList.remove('loading')
    guideButton.classList.remove('bgRed')
    guideButton.classList.add('bgGreen')
  }

  if (scaffold && !scaffoldUp && scaffold.position.y > -20) {
    scaffold.position.y -= 0.05
  }

  if (scaffold && !scaffoldUp && scaffold.position.y < -19) {
    guideText.innerText = 'Guide: off'
    guideText.classList.remove('loading')
  }

  const scaffoldAnimating = (scaffold && scaffoldUp && scaffold.position.y < 0) || (scaffold && !scaffoldUp && scaffold.position.y > -20) 

  const timeMultiplier = scaffoldAnimating ? 0.75 : 40
  const intensityMultiplier = scaffoldAnimating ? 10 : 1

  // Camera shake
  camera.position.x =
    originalPosition.x + noise(0.01, 0.01 + time / timeMultiplier) * (intensity / intensityMultiplier)
  camera.position.y =
    originalPosition.y + noise(-0.01, -0.01 + -time / timeMultiplier) * (intensity / intensityMultiplier)
  camera.position.z =
    originalPosition.z + noise(0.01, 0.01 + time / timeMultiplier) * (intensity / intensityMultiplier)

  camera.fov = scaffoldAnimating ? FOV : FOV + (noise(0.01, 0.01 + time / timeMultiplier) * (intensity / intensityMultiplier) * 2)
  camera.updateProjectionMatrix()

  for (let i = 0; i < orbCount * 3; i++) {
    if (loc === 1) {
      orbPosArray[i] = orbPosArray[i] + 0.005
    }
    if (loc !== 2) {
      loc++
    } else {
      loc = 0
    }
  }

  orbGeometry.setAttribute(
    'position',
    new THREE.BufferAttribute(orbPosArray, 3)
  )

  composer.render()
  requestAnimationFrame(draw)
}

addEventListener('resize', function () {
  const width = window.innerWidth
  const height = window.innerHeight

  camera.aspect = width / height
  renderer.setSize(width, height)
  composer.setSize(width, height)

  camera.aspect = width / height
  renderer.setSize(width, height)

  camera.fov = FOV
  camera.updateProjectionMatrix()
})

draw()
