// HITBOXES
export const MENU_HITBOX = document.getElementById('menu-hitbox')
export const WORK_HITBOX = document.getElementById('work-hitbox')
export const EXPERIMENTS_HITBOX = document.getElementById('experiments-hitbox')
export const INFO_HITBOX = document.getElementById('info-hitbox')
export const LOGO_MINUS_HITBOX = document.getElementById('logo-minus-hitbox')
export const TERMINAL_MINUS_HITBOX = document.getElementById(
  'terminal-minus-hitbox'
)
export const WORK_MINUS_HITBOX = document.getElementById('work-minus-hitbox')
export const EXPERIMENTS_MINUS_HITBOX = document.getElementById(
  'experiments-minus-hitbox'
)
export const INFO_MINUS_HITBOX = document.getElementById('info-minus-hitbox')
export const WORK_PLUS_HITBOX = document.getElementById('work-plus-hitbox')
export const EXPERIMENTS_PLUS_HITBOX = document.getElementById(
  'experiments-plus-hitbox'
)
export const INFO_PLUS_HITBOX = document.getElementById('info-plus-hitbox')

// BUTTONS
export const LOGO_BUTTON = document.getElementById('logo-button')
export const TERMINAL_BUTTON = document.getElementById('terminal-button')
export const WORK_BUTTON = document.getElementById('work-button')
export const EXPERIMENTS_BUTTON = document.getElementById('experiments-button')

// PANES
export const LOGO_PANE = document.getElementById('logo-pane')
export const TERMINAL_PANE = document.getElementById('terminal-pane')
export const WORK_PANE = document.getElementById('work-pane')
export const EXPERIMENTS_PANE = document.getElementById('experiments-pane')
export const MENU_PANE = document.getElementById('menu-pane')
export const INFO_PANE = document.getElementById('info-pane')

// ANIMATION
export const PLUS_HEIGHT = document.getElementById('plus-height')
